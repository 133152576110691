import React from 'react'
import Mail from '../../Assets/images/mail.svg'
import Inputtt from '../SelectValue/Inputttt'
import Textarea from '../SelectValue/TextArea'
import Button from '../SelectValue/Button'
function Contactform() {
  return (
    <div className=' mx-4 md:mx-28 my-[96px] rounded-xl bg-[#0F0A0A]'>
        <div className=' p-4 sm:p-10  grid sm:grid-cols-2 gap-5'>
            <div className=' sm:w-[70%]'>
                <h1 className=' text-[24px] font-[600] text-primary-color1 mb-3'>Inquiry submissions</h1>
                <p className=' font-[500] mb-6 text-[16px]'>For inquiry/complain submissions. Please submit using this</p>
                <img src={Mail} alt='mail'/>
                <p className=' font-[600] mb-3 text-[16px]'>Email</p>
                <p className=' font-[500] text-[16px]'>Support@toppexprime.com</p>
            </div>
            <div>
                <form>
                    <div className=' grid gap-5 sm:grid-cols-2'>
                        <Inputtt
                            label={"First name"}
                        />
                         <Inputtt
                            label={"Last name"}
                        />
                        <div className=' sm:col-span-2'>
                        <Inputtt
                            label={"Email"}
                        />
                        </div>
                        <div className=' sm:col-span-2'>
                        <Inputtt
                            label={"Subject"}
                        />
                        </div>
                        <div className=' sm:col-span-2'>
                            <Textarea
                                label={"Message"}
                            />
                        </div>
                    </div>
                    <div className=' flex justify-end'>
                        <Button
                            name={"Send message"}
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Contactform