import React from 'react'
import Top from '../../Components/Withdrawal/Top'
import WithdrawalTable from '../../Components/Withdrawal/WithdrawalTable'

function Withdrawal() {
  return (
    <div>
      <Top/>
      <WithdrawalTable/>
    </div>
  )
}

export default Withdrawal