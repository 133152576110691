import React, { useState } from 'react'
import Textarea from '../SelectValue/TextArea'
import Button from '../SelectValue/Button'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import postServices from '../../services/post-services';
import Loading from '../SelectValue/Loading';
function AddMessages() {
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)

    const handleSumbit = (e) =>{
      e.preventDefault()
      setLoading(true)
      postServices.sendMessage(user.user_id,message).then(
        res =>{
          setLoading(false)
          if(res.data.statusCode === '200'){
            toast.success(res.data.message, {
                           
              onClose: () => {
                window.location.reload()
              }
            })
          }else{
            toast.error(res.data.message,
              {
                position: toast.POSITION.TOP_LEFT
              })
          }
        }
      ).catch(
        err => {
          setLoading(false)
        }
      )
 }
  return (
    <div className=' rounded-xl border bg-[#0F0A0A] border-primary-color p-4 my-6'>
    <Loading
      open={loading}
    />
      <ToastContainer/>
       <h1 className=' text-primary-color font-bold text-[24px] mb-4'>Send message</h1>
       <form onSubmit={handleSumbit}>
        <Textarea
            label={"Send message to admin"}
            value={message}
            onChange={setMessage}
        />
        <div className=' my-5'>
            <Button
                name={'Send'}
            />
        </div>
       </form>
    </div>
  )
}

export default AddMessages