import React from 'react'

function Top() {
  return (
    <div className=' pt-24 sm:pt-56 px-4 md:px-28 '>
    <div className=' flex justify-center mx-auto '>
    <div>
    <h1 className=' text-[32px] text-center font-[800] sm:text-[40px] mb-5'>Do not hesitate to  <span className=' text-primary-color1'>reach out.</span></h1>
        <p className=' font-[500] text-[20px] text-center'>Just fill in the contact form here and we will reply within 24hours</p>
    </div>
       
    </div>
</div>
  )
}

export default Top