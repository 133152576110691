import React from 'react'

function WalletCom({color, name, onClick}) {
  return (
    <div onClick={onClick} className={ `my-3 border-2 py-3 px-5 cursor-pointer rounded-lg border-primary-color ${color}`}>
        <p className=' text-center text-black'>{name}</p>
    </div>
  )
}

export default WalletCom