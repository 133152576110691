import axios from "axios";
import authHeader from "./AuthHeader";
const BASEURL = 'https://toptrade.com.ng/api_toptrade/v1/api'

const getBalance = (id) =>{
    return axios.get(BASEURL + `/balance_summary?user_id=${id}`, { headers: authHeader() })
}
const getWalletList = () =>{
    return axios.get(BASEURL + '/list_wallet_type' , { headers: authHeader() })
}
const getTransactionHistory = (id) =>{
    return axios.get(BASEURL + `/user_transaction_list?user_id=${id}` , { headers: authHeader() })
}
const getUserAddFundsHistory = (id) =>{
    return axios.get(BASEURL + `/user_add_funds_transaction_list?user_id=${id}` , { headers: authHeader() })
}
const getUserWithdrawalHistory = (id) =>{
    return axios.get(BASEURL + `/user_withdrawal_transaction_list?user_id=${id}` , { headers: authHeader() })
}
const getListOfMessages = (id) =>{
    return axios.get(BASEURL + `/list_user_message?user_id=${id}` , { headers: authHeader() })
}

const getServices = {
    getBalance,
    getWalletList,
    getTransactionHistory,
    getUserAddFundsHistory,
    getUserWithdrawalHistory,
    getListOfMessages
}

export default getServices