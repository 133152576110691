import React, { useEffect, useState } from 'react'
import NonnegativeInputField from '../SelectValue/NonnegativeInputfield'
import Inputmain from '../SelectValue/inputMain'
import Button from '../SelectValue/Button'
import getServices from '../../services/get-services'
import Loading from '../SelectValue/Loading'
import postServices from '../../services/post-services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Top() {
  const [portBalance, setPortBalance] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [totalBonus, setTotalBonus] = useState(0)
  const [totalWithdrawal, setTotalWIthdrawal] = useState(0)
  const [walletTrans, setWalletTrans] = useState([])
  const [amount, setAmount] = useState(0)
  const [withdrawalWalletAddress, setWithdrawalWalletAddress] = useState('')
  const [walletAddress, setWalletAddress] = useState('')
  const [password, setPassword] = useState('')
  const [walletName, setWalletName] = useState('')
  const details = localStorage.getItem('userdetails')
  const user =  JSON.parse(details)
  const [loading, setLoading] = useState(false)
  useEffect(() =>{
    setLoading(true)
    getServices.getBalance(user.user_id).then(
        res =>{
          setLoading(false)
           if(res.data.statusCode === '200'){
            setPortBalance(res.data.result[0].portfolio_balance)
            setTotalBalance(res.data.result[0].total_profit)
            setTotalBonus(res.data.result[0].total_bonus)
            setTotalWIthdrawal(res.data.result[0].total_withdrawal)
           }
        }
    ).catch(
     err =>{
      setLoading(false)
     }
    )
  },[user.user_id])

  useEffect(() =>{
    getServices.getWalletList().then(
        res =>{
          if(res.data.statusCode === '200'){
            setWalletTrans(res.data.result)
          }
        }
    ).catch(

    )
  },[])
  const handleSelectChange = (event) => {
    // Update the state with the new selected option
    setWithdrawalWalletAddress(event.target.value);

    // Optionally, retrieve the name of the select field
    const selectedName = walletTrans.find(option => option.wallet_address === event.target.value).wallet_name;
    setWalletName(selectedName)
    console.log('Selected value:', event.target.value);
    console.log('Select field name:', selectedName);
  };

   const handleSumbit = (e) =>{
        e.preventDefault()
        setLoading(true)
        postServices.fundsWithdrawal(amount,walletName,walletAddress,withdrawalWalletAddress,password,user.user_id).then(
          res =>{
            setLoading(false)
            if(res.data.statusCode === '200'){
              toast.success(res.data.message, {
                             
                onClose: () => {
                  window.location.reload()
                }
              })
            }else{
              toast.error(res.data.message,
                {
                  position: toast.POSITION.TOP_LEFT
                })
            }
          }
        ).catch(
          err => {
            setLoading(false)
          }
        )
   }

  return (
    <div className=' my-5 border border-primary-color rounded-lg p-4 bg-[#0F0A0A]'>
    <Loading
      open={loading}
    />
    <ToastContainer/>
        <h1 className=' text-2xl font-bold text-primary-color1'>Make new withdrawal</h1>
        <form onSubmit={handleSumbit}>
        <div className=' grid sm:grid-cols-2 md:grid-cols-3 gap-6 my-4'>
          <div className=' my-2'>
            <label className=' block mb-3 text-white'>WITHDRAWAL ACCOUNT</label>
            <select required className='w-full  border bg-white p-3.5 rounded-md'>
              <option>Portfolio Balance - ({portBalance})</option>
              <option>Total Profit - ({totalBalance})</option>
              <option>Total Bonus - ({totalBonus})</option>
              <option>Total Withdrawal - ({totalWithdrawal})</option>
            </select>
          </div>
          <div className=' my-2'>
            <NonnegativeInputField
              label={'WITHDRAWAL AMOUNT (USD)'}
              valuee={amount}
              onChange={setAmount}
            />
          </div>
          <div className=' my-2'>
            <label className=' block mb-3 text-white'>Preferred Withdrawal Method</label>
            <select 
            value={withdrawalWalletAddress}
            onChange={handleSelectChange}
            required className='w-full  border bg-white p-3.5 rounded-md'>
              <option>Select</option>
              {walletTrans.map((wallet) =>{
                return <option key={wallet.wallet_address} value={wallet.wallet_address}>{wallet.wallet_name}</option>
              })}
            </select>
          </div>
        </div>
        <div>
            <ul>
                <li className=' text-white'>If you select Bitcoin as your withdrawal mode, enter your correct Bitcoin wallet address.</li>
                <li className=' text-white'>If you selected Ethereum as your withdrawal mode, enter your correct Ethereum wallet address.</li>
                <li className=' text-white'>If you selected USDT money as your withdrawal method, enter your correct USDT (TRC 20) wallet address.</li>
                <li className=' text-white'>If you provide incorrect details for a money transfer, we cannot be held responsible for any funds sent to the wrong account.</li>
                <li className=' text-white'>If there are any issues with disbursing your money, an administrator will contact you via email.</li>
            </ul>
        </div>
        <div className=' my-4'>
            <Inputmain
              label={'WALLET ADDRESS'}
              value={walletAddress}
              onChange={setWalletAddress}
              type='text'
            />
            <Inputmain
              label={'Enter your password to confirm'}
              value={password}
              onChange={setPassword}
              type='text'
            />
            <div className=' mt-4 flex justify-end'>
                <Button
                  name={'Withdraw funds'}
                />
            </div>
        </div>
        </form>
    </div>
  )
}

export default Top