import React, { useEffect, useState }  from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import getServices from '../../services/get-services';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#CF8808',
    color: 'black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
function WalletTable() {
  const [transaction, setTransaction] = useState([])
  const [page, setPage] = useState(0);
  // const [loading, setLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const details = localStorage.getItem('userdetails')
  const user =  JSON.parse(details)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() =>{
    // setLoading(true)
        getServices.getUserAddFundsHistory(user.user_id).then(
          response =>{
            // setLoading(false)
            if(response.data.statusCode === '200'){
                setTransaction(response.data.result)
            }
          }
        ).catch(
            err =>{
              // setLoading(false)
              console.log("error occures")
            }
        )
  },[user.user_id])
  return (
    <div className=' rounded-xl border bg-[#0F0A0A] border-primary-color p-4 my-6'>
     <h1 className=' text-primary-color font-bold text-[24px] mb-4'>Payment history</h1>
     <p className=' mb-3 text-white text-[16px]'>A list of all your approved and pending payments.</p>
     <Paper className=' mx-auto overflow-auto ' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>Amount</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Type</StyledTableCell>
            <StyledTableCell align="left">Transaction Id</StyledTableCell>
            <StyledTableCell align="left">Date/Time</StyledTableCell>
            
            </TableRow>
          </TableHead>
          <TableBody>
            {transaction
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tran) => (
                
            <TableRow
              key={tran.ttxn_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell align="left">${tran.amount}</TableCell>
              <TableCell component="th" scope="row">
             <span className=' capitalize'>{tran.txn_status === 'APPROVED' ? <span className=' text-green-600'>Approved</span> : tran.txn_status === 'PENDING' ?  <span className=' text-yellow-600'>Pending</span>: <span className=' text-red-700'>Rejected</span>}</span> 
              </TableCell>
              <TableCell align="left">{tran.txn_type}</TableCell>
              <TableCell align="left">{tran.wallet_transaction_id} </TableCell>
              {/* <TableCell align="left">{tran.narration}</TableCell> */}
              <TableCell align="left">{tran.inserted_dt} </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={transaction.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {transaction.length === 0 && <p className=' text-center font-bold text-red-700 mt-5'>No transaction</p>}
    </Paper>
    </div>
  )
}

export default WalletTable