import React from 'react'

function Topsec() {
  return (
    <div className=' pt-24 sm:pt-56 px-4 md:px-28 '>
        <div className=' flex justify-center mx-auto '>
        <div>
        <h1 className=' text-[32px] text-center font-[800] sm:text-[40px] mb-5'>Putting our <span className=' text-primary-color1'>clients first</span></h1>
            <p className=' font-[500] text-[20px] text-center'>We're empowering our clients by helping them take control of their financial lives.</p>
        </div>
           
        </div>
    </div>
  )
}

export default Topsec