import React from 'react'

function Ab() {
  return (
    <div className=' px-4 my-20 md:px-28'>
        <div className=' grid sm:grid-cols-2 gap-5'>
            <h1 className=' font-[700] sm:text-[40px]  text-[32px]'>About Toppexprime</h1>
            <div className=' '>
                <p className=' font-[500] text-[18px]'>At Toppexprime, we understand that not everyone has the time, expertise, or confidence to trade independently. That's why we have developed a unique copy trading system that enables you to automatically replicate the trades of experienced and successful traders. With our platform, you can follow the strategies of top traders, benefiting from their knowledge and expertise without needing to actively trade yourself.</p>
            </div>
        </div>
    </div>
  )
}

export default Ab