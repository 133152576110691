import React from 'react'
import I1 from '../../Assets/images/i1.svg'
import I2 from '../../Assets/images/i2.svg'
import I3 from '../../Assets/images/i3.svg'
import I4 from '../../Assets/images/i4.svg'
import AccessCom from './AccessCom'
function Options() {
  return (
    <div className=' px-4 md:px-28 my-[96px] '>
    <div className=' grid sm:grid-cols-2 gap-5'>
    <AccessCom
        img={I1}
        title={"Stocks"}
        con={"Access 19,000+ stocks across core and emerging markets on 40+ exchanges worldwide."}
     />
      <AccessCom
        img={I2}
        title={"Futures"}
        con={"Access 300+ futures covering equity indices, energy, metals, agriculture, rates and more."}
     />
      <AccessCom
        img={I3}
        title={"Crypto Options"}
        con={"Access 1,200+ asset options across cryptocurrencies, cryptotokens, digital assets and more."}
     />
      <AccessCom
        img={I4}
        title={"Security"}
        con={"We provide the secuirty of your funds and personal information. our platform implements industry standard security measures."}
     />
    </div>
        
    </div>
  )
}

export default Options