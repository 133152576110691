import React from 'react'
import ProfileDetails from '../../Components/Profile/ProfileDetails'

function Profile() {
  return (
    <div>
      <ProfileDetails/>
    </div>
  )
}

export default Profile