import React, {useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import { Link } from 'react-router-dom'
import Loading from '../SelectValue/Loading'
// import postService from '../../Services/post-service'
import Logo from '../../Assets/images/logoo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import postServices from '../../services/post-services'
// import Constant from '../../Constant'
function Loginform() {
  const [loading, setLoading] = useState(false)
    const initialValues = { 
        email: '',
        password: '',

      }
      const validationSchema = Yup.object({
        
      //  email:Yup
      //  .string()
      //  .email()
      //  .required('Please Enter your Email'),
       password: Yup.string()
       .required('Please Enter your password')
     
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        setLoading(true)
        const {email, password} = values
        postServices.login(email, password).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.statusCode === '200'){
              
              localStorage.setItem('userdetails', JSON.stringify(response.data.user_detail))
              localStorage.setItem('accountStatuss',response.data.user_detail.user_id )
             
              return   window.location.href = '/dashboard'
             } else if(response.data.statusCode === '202'){
              toast.error(response.data.message,
                {
                  position: toast.POSITION.TOP_LEFT
                })
                setTimeout(() => {
                  window.location.href = '/verify'
                }, 5000);
              // return   window.location.href = '/verify'
             }
             else{
            
              toast.error(response.data.message,
                {
                  position: toast.POSITION.TOP_LEFT
                })
             }
             
            },
            (error) => {
              setLoading(false)
                return  toast.error('Login failed. Try again',
                {
                  position: toast.POSITION.TOP_LEFT
                })
              }
        )
        // navigate('/dashboard')
      }

  return (
    <div className=' sm:w-[50%] mx-auto px-5 pt-10'>
    <Loading
      open={loading}
    />
    <ToastContainer/>
     <img src={Logo} className=" mx-auto mb-5 "  alt=" logo" /> 
        <div className=' bg-white shadow-lg p-10 rounded-[16px]'>
       
            <p className=' mt-6 text-[18px] text-black mb-5 md:text-[40px] font-[500]'>Login to your account</p>
           
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            
               <FormikControl
                control='input'
                label='Email address'
                name='email'
              />  
               
               <FormikControl
                control='password'
                label='Password'
                name='password'
              /> 
              <div className=' flex justify-end mt-3 mb-7'>
                <Link to='/forgetpassword'><span className=' text-primary-color'>Forgot password?</span></Link>
              </div> 
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Login'
              />
              </div>
              <div className=' mt-4'>
                <p className=' font-[500] text-black text-center'>Don’t have an account? <Link to='/signup' className=' text-primary-color'>Create account</Link> </p>
              </div>
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
  )
}

export default Loginform