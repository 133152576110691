
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Aboutus from './Pages/About';
import Contactus from './Pages/Contact';
import Login from "./Pages/Login";
import Signup from './Pages/Signup';
import ForgetPass from './Pages/Forgetpass'
import VerifyAccount from './Pages/Signup/VerifyAccount';
import NewPass from './Pages/Forgetpass/NewPass';
import Dashboard from './Pages/Dashboard/Dashboard';
import React from 'react';


const Logout = React.lazy(() => import('./Pages/logout'));
class App extends React.Component {

  constructor(props) {
    super(props);
    // var token = localStorage.getItem('token');
   
    const details = localStorage.getItem('accountStatuss')
    
    if(details === null){
      this.loggedIn = false;
     
    }else{
      this.loggedIn = true;
      // setTimeout(this.logout, 100000);
     
    }
  }

  logout() {
    // localStorage.removeItem('token');

    localStorage.removeItem('userdetails');
    localStorage.removeItem('accountStatuss');
    this.loggedIn = false;
    window.location.href = '/';
  }
    render() {
    return this.loggedIn === true? (
      <div>
      <BrowserRouter>
      <Routes>
        
        <Route path='/logout' element={ <Logout /> } />
        <Route path='*' element={<Dashboard/>}/>
      </Routes>

      
      </BrowserRouter>  
      </div>
    ): (
      <div>
      <BrowserRouter>
   
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/aboutus' element={<Aboutus/>}/>
        <Route path='/contactus' element={<Contactus/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/signup' element={<Signup/>}/>
        <Route path='/forgetpassword' element={<ForgetPass/>} />
        <Route path='/verify' element={<VerifyAccount/>}/>
        <Route path='/newpassword' element={<NewPass/>}/>
        <Route path='*' element={<Home/>}/>
      </Routes>
      </BrowserRouter>  
      </div>
    )
      ;
  }
}

export default App;
