// import React, { useEffect } from 'react';

// const CryptoPriceWidget = () => {
//   useEffect(() => {
//     // Unique identifier for the script
//     const scriptId = 'crypto-price-widget-script';

//     // Function to initialize the widget
//     const initializeWidget = () => {
//       if (window.crCryptocoinPriceWidget) {
//         window.crCryptocoinPriceWidget.init({
//           // Widget initialization parameters
//           // ...
//         });
//       }
//     };

//     // Check if the script already exists
//     let existingScript = document.getElementById(scriptId);
//     if (!existingScript) {
//       // Create a new script element
//       const script = document.createElement('script');
//       script.id = scriptId;
//       script.src = "https://co-in.io/widget/pricelist.js?items=BTC%2CETH%2CLTC%2CXMR%2CDASH%2CGIVE%2CDEM%2CKDC%2CUSDE%2CTAG%2CXBS%2CXC%2CTOR%2CXDP%2CDANK";
//       script.async = true;

//       // Append the script to the document
//       document.body.appendChild(script);

//       // Add onload event listener
//       script.onload = () => {
//         initializeWidget();
//       };
//     } else {
//       // If the script already exists, just initialize the widget
//       initializeWidget();
//     }

//     // Cleanup function
//     return () => {
//       existingScript = document.getElementById(scriptId);
//       if (existingScript) {
//         // Remove the script from the document
//         document.body.removeChild(existingScript);
//       }
//       // Additional cleanup for the widget, if necessary
//     };
//   }, []); // Empty dependency array ensures this runs only once

//   return (
//     <div 
//       id="crypto-price-widget-container"
//       style={{ width: '50%',  height: 'auto', maxWidth: '600px', margin: '1px auto' }}
//     >
//       {/* Placeholder or loading indicator can be placed here */}
//     </div>
//   );
// };

// export default CryptoPriceWidget;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function CryptoPrices() {
    const [cryptoPrices, setCryptoPrices] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currency, setCurrency] = useState('usd'); // State to track the selected currency

    useEffect(() => {
        axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,usdt,dash&vs_currencies=${currency}`)
            .then(response => {
                setCryptoPrices(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [currency]); // useEffect dependency array includes currency

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading prices!</p>;

    return (
        <div>
           
        
        <div className='my-4 border p-3'>
            <h2 className='font-bold mb-3'>Crypto Prices</h2>
            <div className=' flex mb-3 gap-6'>
                <button className=' border rounded-md p-2' onClick={() => setCurrency('usd')}>USD</button>
                <button className=' border rounded-md p-2'  onClick={() => setCurrency('eur')}>EUR</button>
            </div>
            <p>Bitcoin: <span className='font-bold'>{cryptoPrices.bitcoin?.[currency]}</span> {currency.toUpperCase()}</p>
            <p>Ethereum: <span className='font-bold'>{cryptoPrices.ethereum?.[currency]}</span> {currency.toUpperCase()}</p>
            {/* <p>Tether (USDT): <span className='font-bold'>{cryptoPrices.usdt?.[currency]}</span> {currency.toUpperCase()}</p> */}
            <p>Dash: <span className='font-bold'>{cryptoPrices.dash?.[currency]}</span> {currency.toUpperCase()}</p>
        </div>
        </div>
    );
}

export default CryptoPrices;

