import React, { useEffect, useState }  from 'react'
import getServices from '../../services/get-services';
import Loading from '../SelectValue/Loading';

function ListMessages() {
    const [messages, setMessages] = useState([])
    const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)
    const [loading, setLoading] = useState(false)

    useEffect(() =>{
         setLoading(true)
            getServices.getListOfMessages(user.user_id).then(
              response =>{
                setLoading(false)
                if(response.data.statusCode === '200'){
                    setMessages(response.data.result.reverse())
                }
              }
            ).catch(
                err =>{
                  setLoading(false)
                  console.log("error occures")
                }
            )
      },[user.user_id])
  return (
    <div className='rounded-xl border bg-[#0F0A0A] border-primary-color p-4 my-6'>
    <Loading
      open={loading}
    />
      <h1 className=' text-primary-color font-bold text-[24px] mb-4'>Messages</h1>
      {messages.map((message, index) =>{
        return <div className={message.sender === 'ADMIN' ? ' flex my-3 justify-start ':'flex my-3 justify-end '} key={index}>
            <div className=' inline-block border-primary-color  bg-white border p-3'>
            <h1 className=' text-sm text-black  mb-4'>{message.sender === 'ADMIN' ?'Admin':'You'}</h1>
            <p className='text-black font-bold'>{message.message}</p>
            <p className=' text-black font-light text-end'>{message.inserted_dt}</p>
            </div>
            
        </div>
      }) }
        <div >

        </div>
    </div>
  )
}

export default ListMessages