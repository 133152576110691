import axios from "axios";
import authHeader from "./AuthHeader";
const BASEURL = 'https://toptrade.com.ng/api_toptrade/v1/api'

const signup = (fName, lName, email, username, phone, pass) =>{
      const  value = {"firstName" : fName, "lastName" : lName, "username" : username, "emailAddress" : email, "phoneNumber": phone, "password": pass}

     return axios.post( BASEURL+'/user_sign_up', value, { headers: authHeader() })
}
const login = (email, password) =>{
    const value = {"emailAddress" : email, "password": password}
   return axios.post(BASEURL + '/user_login', value, { headers: authHeader() })
}

const addFund = (walletAddress, amount, walletTransactionId, userId) =>{
    const value = {"walletAddress" : walletAddress, "amount" :amount, "walletTransactionId" :walletTransactionId, "userId" : userId}

    return axios.post(BASEURL + '/add_funds', value, { headers: authHeader() })
}

const fundsWithdrawal = (amount, walletType, walletTypeAddress, receiverWalletAddress,userPassword,userId) =>{
        const value = {"amount" :amount,
                        "walletType" : walletType, 
                        "walletTypeAddress" : walletTypeAddress, 
                        "receiverWalletAddress" : receiverWalletAddress,
                        "userPassword" : userPassword,
                        "userId" : userId
                    }
                    return axios.post(BASEURL + '/withdrawal', value, { headers: authHeader() })
}
 const updatePassword = (oldPassword, newPassword, userId) =>{
    const value  = {"oldPassword" : oldPassword, "newPassword" :newPassword, "userId" : userId}
    return axios.post(BASEURL + '/change_password', value, { headers: authHeader() })
 }

 const sendMessage = (userId, message) =>{
    const value  = {"message" : message, "user_id" : userId}
    return axios.post(BASEURL + '/user_create_message', value, { headers: authHeader() })
 }
 const updateProfile = (fName, lName, email) =>{
    const value  = {"firstName" : fName, "lastName" : lName,  "emailAddress" :email}
    return axios.post(BASEURL + '/update_user_account', value, { headers: authHeader() })
 }
 const forgetPass = (email) =>{
   const value  = {"emailAddress" : email}
   return axios.post(BASEURL + '/forget_password', value, { headers: authHeader() })
}
const  changePass = (email, newPassword) =>{
   const value  = {"emailAddress" : email, "password" : newPassword}
   return axios.post(BASEURL + '/create_new_password', value, { headers: authHeader() })
}

const verifyEmail = (otp, uid) =>{
   const value  = {"otpKey" : otp}
   return axios.post(BASEURL + '/activate_user_account', value, { headers: authHeader() })
}
const postServices = {
    login, 
    verifyEmail,
    signup,
    sendMessage,
    updatePassword,
    fundsWithdrawal,
    addFund,
    updateProfile,
    forgetPass,
    changePass
}

export default postServices