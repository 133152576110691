import React from 'react'
import NavBar from '../../Components/Navbar'
import TopSec from '../../Components/Home/TopSec'
import Access from '../../Components/Home/Access'
import Options from '../../Components/Home/Options'
import Steps from '../../Components/Home/Steps'
import Footer from '../../Components/Footer'
import Faq from '../../Components/Home/Faq'

function Home() {
  return (
    <div>
        <NavBar/>
      
        <TopSec/>
        <div>
        <iframe className="w-full h-10" src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=light&pref_coin_id=1505&invert_hover=" title='Market price'></iframe>
        </div>
        <Access/>
        <div>
        <iframe className="w-full h-10" src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=light&pref_coin_id=1505&invert_hover=" title='Market price'></iframe>
        </div>
        <Options/>
        <Steps/>
       
        <Faq/>
        <Footer/>
    </div>
  )
}

export default Home