import React, {useEffect, useState} from 'react'
import Logo from '../Assets/images/logoo.png'
import FooterInput from './SelectValue/FooterInput'
import { Link } from 'react-router-dom'
function Footer() {
        const [year, setYear] = useState('')

        useEffect(() =>{
            const date = new Date();
            setYear(date.getFullYear())
        }, [])

  return (

    <div className=' mt-20 pb-6  px-8 md:px-24'>
          <div className=' sm:col-span-2'>
            <h1 className=' font-[700] text-[28px] md:text-[40px] md:w-[50%] mx-auto text-center mb-4'>Subscribe to our newsletter. Be the first to <span className=' text-primary-color1'>get updates</span> from us</h1>
           <div className=' mx-auto md:w-[60%]'>
           <FooterInput/>
           </div>
            
            </div>
        <div className=' sm:flex   my-6 justify-between'>
            <div className=''>
                    <img src={Logo} className=' mx-auto' alt='Logo'/>
            </div>
            <div>
            <div className=' flex flex-col pt-6 justify-end'>
            <div>
            <Link to='/' className=' text-[16px] font-[600] p-2'>
                Terms & Conditions
                </Link>
                <Link to='/' className=' text-[16px] font-[600] p-2'>
                Privacy policy
                </Link>
            </div>
           
            </div>
               
            </div>
           

        </div>
        <div className='  mt-10 '>
            <div className='  pt-5 md:pt-10'>
            <p className=' text-center text-[#777777]'>Copyright ©{year} Toppexprime. All Rights Reserved.</p>
            <div>
           
            </div>
            </div>
        </div>
    </div>
  )
}

export default Footer