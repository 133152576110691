import React, { useEffect, useState } from 'react'
import Wallets from '../../Components/Wallet/Wallets'
import WalletTable from '../../Components/Wallet/WalletTable'
import getServices from '../../services/get-services'
import Loading from '../../Components/SelectValue/Loading'

function Wallet() {
 
  const details = localStorage.getItem('userdetails')
  const user =  JSON.parse(details)
  const [loading, setLoading] = useState(false)
  useEffect(() =>{
    setLoading(true)
    getServices.getBalance(user.user_id).then(
        res =>{
          setLoading(false)
           if(res.data.statusCode === '200'){
           
           }
        }
    ).catch(
     err =>{
      setLoading(false)
     }
    )
  },[user.user_id])
  return (
    <div>
    <Loading
      open={loading}
    />
      
    <Wallets/>
    <WalletTable/>
    </div>
  )
}

export default Wallet