import React, { useEffect, useRef, useState } from 'react';

const TradingViewChart = () => {
    const chartContainerRef = useRef(null);
    const [scriptLoaded, setScriptLoaded] = useState(false);
  
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/tv.js';
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
    }, []);
  
    useEffect(() => {
      if (scriptLoaded && chartContainerRef.current) {
        new window.TradingView.widget({
          container_id: chartContainerRef.current.id,
          symbol: 'AAPL',
            interval: 'D',
            theme: 'dark',
            style: '1',
            locale: 'en',
            toolbar_bg: '#f1f3f6',
            enable_publishing: false,
            withdateranges: true,
            range: 'ytd',
            hide_side_toolbar: false,
            allow_symbol_change: true,
            autosize: true,
        });
      }
    }, [scriptLoaded]);

  return <div  >
        <div ref={chartContainerRef} id="tv-chart" className=' w-full h-[70vh]'/>
  </div>;
};

export default TradingViewChart;
