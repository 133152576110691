import React from 'react'
import Back from '../../Components/Back'
import NewPasswordForm from '../../Components/ForgetPass/NewPasswordForm'

function NewPass() {
  return (
    <div>
        <Back/>
        <NewPasswordForm/>
    </div>
  )
}

export default NewPass