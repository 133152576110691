import React from 'react'

function StoryCom({title, con}) {
  return (
    <div className=' bg-[#0F0A0A] p-3 rounded-lg'>
        <h1 className=' text-primary-color1 text-[24px] font-[700] mb-4'>{title}</h1>
        <p className=' font-[500] sm:text-[18px]'>{con}</p>
    </div>
  )
}

export default StoryCom