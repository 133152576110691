import React from 'react'
import NavBar from '../../Components/Navbar'
import Topsec from '../../Components/About/Topsec'
import Ab from '../../Components/About/Ab'
import Story from '../../Components/About/Story'
import Footer from '../../Components/Footer'
import Support from '../../Components/About/Support'

function Aboutus() {
  return (
    <div>
      <NavBar/>
      <Topsec/>
      <Ab/>
      <Story/>
      <Support/>
      <Footer/>
    </div>
  )
}

export default Aboutus