import React from 'react'
import SignupForm from '../../Components/Signup/Sigupform'
import Back from '../../Components/Back'

function Signup() {
  return (
    <div>
    <Back/>
        <SignupForm/>
    </div>
  )
}

export default Signup