import React from 'react'

function CardCom({name, amount, con}) {
  return (
    <div className=' rounded-lg p-4 my-4 bg-[#0F0A0A]'>
        <h1 className=' text-primary-color font-[700] text-[24px] mb-4'>{name}</h1>
        {/* <p className=' text-white font-normal text-[16px] capitalize mb-4' >{con}</p> */}
        <p className=' text-white font-extrabold text-[30px]'> $  {amount}</p>
    </div>
  )
}

export default CardCom