import React from 'react'
import AccessCom from './AccessCom'
import I1 from '../../Assets/images/s1.svg'
import I2 from '../../Assets/images/s2.svg'
import I3 from '../../Assets/images/s3.svg'
function Steps() {
  return (
    <div className=' px-4 md:px-28 my-[96px] ' >
        <h1 className=' text-primary-color1 text-[32px] font-[800] mb-7 sm:text-[48px]'>Simple steps to start trade.</h1>
        <div className=' grid sm:grid-cols-3 gap-5 '>
          <AccessCom
            img={I1}
            title={"Create account"}
            con={"Sign up in a few steps Providing your biometric data and completing your verification process"}
          />
           <AccessCom
            img={I2}
            title={"Fund your account"}
            con={"Verify your account and choose your preferred crypto asset to fund your wallet"}
          />
           <AccessCom
            img={I3}
            title={"Ready to go"}
            con={"Begin your trading journey"}
          />
        </div>
    </div>
  )
}

export default Steps