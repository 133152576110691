import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Profile from '../Profile'
import Wallet from '../Wallet'
import CopyTrade from '../CopyTrade'
import Withdrawal from '../Withdrawal'
import MainDashboard from '../MainDashboard'
import Messages from '../Messages'

function Routess() {
  return (
    <div>
        <Routes>
            <Route path='/dashboard' element={<MainDashboard/>}/>
            <Route path='/profile' element={<Profile/>}/>
            <Route path='/messages' element={<Messages/>}/>
            <Route path='/wallet' element={<Wallet/>}/>
            <Route path='/copytrade' element={<CopyTrade/>}/>
            <Route path='/withdrawals' element={<Withdrawal/>}/>
            <Route path='*' element={<MainDashboard/>}/>
        </Routes>
    </div>
  )
}

export default Routess