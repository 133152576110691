import React, { useEffect, useState } from 'react'
import Inputmain from '../SelectValue/inputMain'
import Button from '../SelectValue/Button'
import postServices from '../../services/post-services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../SelectValue/Loading';
const details = localStorage.getItem('userdetails')
const user =  JSON.parse(details)
function ProfileDetails() {
    const [lName, setLName] = useState('')
    const [fName, setFName] = useState('')
    const [email, setEmail] = useState('')
    const [pNumber, setPNumber] = useState('')
    const [loading, setLoading] = useState(false)
    useEffect(() =>{
       setFName(user.firstname)
        setLName(user.lastname)
        setEmail(user.email_address)
        setPNumber(user.phone_number)
    }, [])

    const handleSumbit = (e) =>{
        e.preventDefault()
        setLoading(true)
        postServices.updateProfile(fName,lName,email).then(
          res =>{
            setLoading(false)
            if(res.data.statusCode === '200'){
              toast.success(res.data.message)
            }else{
              toast.error(res.data.message,
                {
                  position: toast.POSITION.TOP_LEFT
                })
            }
          }
        ).catch(
          err => {
            setLoading(false)
          }
        )
   }
  return (
    <div className=' rounded-xl border bg-[#0F0A0A] border-primary-color p-4 my-6'>
    <Loading
        open={loading}
    />
    <ToastContainer/>
        <h1 className=' text-primary-color font-bold text-[24px] mb-4'>Edit Profile</h1>
        <form onSubmit={handleSumbit}>
            <div className=' grid gap-5 grid-cols-2'>
                <Inputmain
                    label={"First name"}
                    value={fName}
                    onChange={setFName}

                    
                />
                  <Inputmain
                    label={"Last name"}
                    value={lName}
                    onChange={setLName}

                />
               
                <Inputmain
                    label={"Email Address"}
                    value={email}
                    onChange={setEmail}
                    readOnly
                />
                <Inputmain
                    label={"Phone number"}
                    value={pNumber}
                    onChange={setPNumber}
                    readOnly
                />
            </div>
            <div className=' mt-5'>
            <Inputmain
                    label={"Enter password to confirm profile update"}
                />    
            </div>
            <div className=' mt-6 flex justify-end'>
                    <Button
                        name={'Update profile'}
                    />
            </div>
        </form>
    </div>
  )
}

export default ProfileDetails