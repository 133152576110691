import React from 'react'
import Button from '../SelectValue/Button'

function Support() {
  return (
    <div className=' my-[96px] px-4  md:px-28'>
       <p className=' font-[700]  text-[24px] mb-4 text-[#777777]'>Support@topxec.com</p>
       <h1 className=' font-[700] text-[32px] mb-5 sm:text-[40px]'>We are always <span className=' text-primary-color1'>ready to assist</span>  you.</h1>
        <Button
            name={"Cotact support"}
        />
    </div>
  )
}

export default Support