import  React, { useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Logo from '../../Assets/images/logoo.png'
import { Link, NavLink, useLocation} from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddchartIcon from '@mui/icons-material/Addchart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Routess from './Routess';

const drawerWidth = 240;




function Dashboard(props) {
    const { window } = props;
    const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)
    const location = useLocation();
    console.log(location)
    const [open, setOpen] = useState(false)
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  
  const sidebar = [
    {
        name: 'Dashboard',
        icon: (
            <DashboardIcon/>
        ),
        link: '/dashboard',
        id: 'dashbord',
    },
    {
        name: 'Deposit',
        icon: (
            <AccountBalanceWalletIcon/>
        ),
        link: '/wallet',
        id: 'wallet',
    },
    {
        name: 'Withdrawal',
        icon: (
            <AccountBalanceIcon/>
        ),
        link: '/withdrawals',
        id: 'withdrawal',
    },
    {
        name: 'Copy Trade chart',
        icon: (
            <AddchartIcon/>
        ),
        link: '/copytrade',
        id: 'ctc',
    },
    {
        name: 'Messages',
        icon: (
            <MarkEmailUnreadIcon/>
        ),
        link: '/messages',
        id: 'message',
    },
    {
        name: 'Profile',
        icon: (
            <AccountBoxIcon/>
        ),
        link: '/profile',
        id: 'profile',
    },
  ]
 

 
 

 
  const drawer = (
    <div className=' bg-[#1E1E1E] h-full'>
     <div className=' pt-2 border-b border-b-gray-300 mb-2 pb-4 bg-[#1E1E1E]  '>
        <img src={Logo}  alt='logo' className='  mx-auto'/>
        
          </div>
     
      <List>
          {sidebar.map((text, index) => (
            <NavLink
             className={({isActive}) =>{
                            return( ' block text-white p-3 mb-3 pl-6 ' + (
                              !isActive ? ' block ' : '  border-l-4  border-l-primary-color text-back-color2 block'
                            ))
                        }}  
                      to={text.link}>
              {text.icon}
              <span className=' pl-3'>{text.name}</span>
           
            </NavLink>
           
            
          ))}
          
        </List>
      <p className='  py-2 mt-20 text-center bg-red-100 text-red-400'><Link to='/logout'> <LogoutIcon/> Logout </Link></p>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
   
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
       className=' pb-4 pt-4 '
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          
         backgroundColor: '#1E1E1E',
        }}
      >
      <div className=' flex justify-between'>
      <div className=' flex justify-between'>
      <Toolbar>
      <IconButton
            
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' , color:'white'} }}
          >
            <MenuIcon />
          </IconButton>
         
                
       
        </Toolbar>
        <div className=' flex flex-col justify-center'>
          <p className=' text-white md:text-[24px] capitalize font-[400]'>Welcome  {user.username}</p>
         </div>
      </div>
     
            <div className=' md:flex hidden justify-center gap-2'>
            {/* <img src={Noti1} className=' inline-block' width='45'  alt='buy'/> */}
            {/* <img src={Noti2} className=' inline-block'  width='30'   alt='buy'/> */}
            <div className=' mt-3 flex gap-2 mr-3 relative cursor-pointer' onClick={() => setOpen(!open)}>
            <div>
            <p className=' px-3 py-2 rounded-[44px] bg-white text-black'>{user.firstname.charAt(0)}</p> 
            </div>
           
            <div>
            <p className=' text-white inline-block text-[16px] mr-4'> {user.firstname} {user.lastname}</p>
            <p className=' text-back-color2 text-[12px]'>{user.username}</p>
            </div>
            <div className=' pt-2'>
            {open ?<ArrowDropUpIcon className=' text-back-color2'/> :<ArrowDropDownIcon className=' text-back-color2'/>} 
              {open && <div className=' mt-3 absolute top-12 right-0 rounded-lg p-6 pr-16 shadow-md text-back-color2 bg-white'>
                <p className=' mb-4 text-black'> <Link to='/profile'><SettingsIcon/> <span>Profile</span></Link> </p>

                <p className=' mb-4 text-red-400'><Link to='/logout'><LogoutIcon/> <span>Logout</span></Link></p>

              </div>}
            </div>
               
            </div>
                    </div>
                 </div>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#1E1E1E' },
          }}
        >
          {drawer}
          

        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,  backgroundColor: '#1E1E1E' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      <div className=' w-full mt-5' >
          <Routess/>
      </div>
        
      </Box>
    </Box>
    </div>
  )
}
Dashboard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

export default Dashboard