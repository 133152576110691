import React from 'react'
import VerifyAccountForm from '../../Components/Signup/Verifyform'
import Back from '../../Components/Back'

function VerifyAccount() {
  return (
    <div>
    <Back/>
        <VerifyAccountForm/>
    </div>
  )
}

export default VerifyAccount