import React from 'react'
import AddMessages from '../../Components/Message/AddMessages'
import ListMessages from '../../Components/Message/ListMessages'

function Messages() {
  return (
    <div>
      <AddMessages/>
      <ListMessages/>
    </div>
  )
}

export default Messages