import React from 'react'
import Loginform from '../../Components/Login/LoginForm'
import Back from '../../Components/Back'

function Login() {
  return (
    <div>
    <Back/>
        <Loginform/>
    </div>
  )
}

export default Login