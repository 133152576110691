import React, {useState} from 'react'
import Button from '../SelectValue/Button'
import PasswordField from '../SelectValue/PasswordFieldC';
import PassError from '../SelectValue/PassError';
import Logo from '../../Assets/images/logoo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../SelectValue/Loading';
import postServices from '../../services/post-services';
import { useNavigate } from 'react-router-dom';
function NewPasswordForm() {
   
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [cPassword, setCPassword] = useState('')
  const [charError, setCharError] = useState(false);
  const [upperError, setUpperError] = useState(false);
  const [lowerError, setLowerError] = useState(false);
  const [numError, setNumError] = useState(false);
  const [specialError, setSpecialError] = useState(false);
  const [maxError, setMaxError] = useState(false);

  const handleChange = (event) => {
    setPassword(event.target.value)
    if (password.length < 8) {
      setCharError(true);
    } else {
      setCharError(false);
    }
    if (!password.match(/[A-Z]/)) {
      setUpperError(true);
    } else {
      setUpperError(false);
    }
    if (!password.match(/[a-z]/)) {
      setLowerError(true);
    } else {
      setLowerError(false);
    }
    if (!password.match(/\d/)) {
      setNumError(true);
    } else {
      setNumError(false);
    }
    if (!password.match(/[@$!%*?&]/)) {
      setSpecialError(true);
    } else {
      setSpecialError(false);
    }
    if (password.length <= 10) {
      setMaxError(true);
    } else {
      setMaxError(false);
    }
    if(!charError && !upperError && !lowerError && !numError && !specialError && !maxError) {
     
    //   onChange(event, true);
    } else {
     
    //   onChange(event, false);
    }
  };
  const email = localStorage.getItem('email')
  const navigate = useNavigate()
    const onSubmit = (e) =>{
        e.preventDefault()
        if(password === cPassword){
          setLoading(true)
            postServices.changePass(email,cPassword).then(
              (response) => {
                
                  console.log(response.data)
                 
                 if(response.data.statusCode === '200'){
                 setLoading(false)
                  return toast.success(response.data.message, {
                           
                    onClose: () => {
                      navigate('/login')
                    }
                  })
                 
                 }else{
                  setLoading(false)
                  toast.error(response.data.message)
                 
                 }
                 
                },
                (error) => {
                  setLoading(false)
                    return  toast.error('Error occuried')
                  })
        }else{
          toast.error('Password not a match')
        }
       
    }
  return (
    <div className='bg-signin-bg h-screen'>
    <ToastContainer/>
    <div className='grid h-[90vh]  place-items-center'>
      <Loading
        open={loading}
      />
    <div className=' md:w-2/5 shadow-[16px] bg-white rounded-lg p-7 md:p-10'>
    <img src={Logo}  className=" "  alt=" logo" /> 
        <p className=' pt-4 text-[18px] text-black mb-5 md:text-[40px] font-[500]'>New password</p>
       <p className=' mb-3 text-[#44474E]'>Enter a secure password with letters and numbers</p>
        <div className='  mt-5'>

        <div >
        <form onSubmit={onSubmit}> 
        {/* <div className=' w-full mb-3'>
          <Inputtt
            label={"Otp"}
            value={otp}
            onChange={setOpt}
          />
        </div> */}
        <div className=' w-full mb-3'>
            <PasswordField
                label='Enter your new password'
                onChange={handleChange}
            />
        </div>
        <div className=' w-full mb-3'>
            <PasswordField
                label='Confirm your new password'
                value={cPassword}
                onChange={(e) =>setCPassword(e.target.value)}
            />
        </div>
       

        <div className='mt-4 sm:flex gap-3'>
        <PassError
            bool={charError}
            text='8+ Characters'
        />
         <PassError
            bool={upperError}
            text='Uppercase'
        />
        <PassError
            bool={lowerError}
            text='Lowercase'
        />
        </div>
        <div className=' mt-4 sm:flex gap-3'>
        <PassError
            bool={numError}
            text='1+ Number'
        />
        <PassError
            bool={specialError}
            text='1 Special Characters'
        />
        </div>
        <div className=' mt-4 sm:flex gap-3'>
        <PassError
            bool={maxError}
            text='10 Characters maximum'
        />
        </div>
       
        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Change password'
          />
        </div>
       
        </form>
         
          </div>
      
        </div>
    </div>
</div>
</div>
  )
}

export default NewPasswordForm