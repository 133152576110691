// import React from 'react'
// import HowInvestCom from './HowInvestCom'

// function HowInvest() {
//   return (
//     <div className=' mt-32'>
//         <HowInvestCom/>
//     </div>
//   )
// }

// export default HowInvest

import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Bg from '../../Assets/images/h1.png'
import Bg1 from '../../Assets/images/h2.png'
import Button from '../SelectValue/Button'

function SampleNextArrow(props) {
  return (
    <div 
    />
  );
}

function SamplePrevArrow(props) {
  return (
    <div
    />
  );
}

export default class SliderHome extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    render() {
      const settings = {
        
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
      return (
        <div className=" ">
       
          <Slider ref={c => (this.slider = c)} {...settings}>
            <div  key={1}>
            <div className="grid md:grid-cols-2 gap-5  " >
            <div>
            <h1 className=' font-[800] mb-5 text-[32px] sm:text-[48px] '>Multi-regulated global broker.</h1>
            <p className=' font-[500] mb-4 text-[16] sm:text-[24px]'>We operate fully within trading laws and regulations</p>
            <Button
                    name='Trade now'
                />
            </div>
            <div>
                    <img src={Bg}  alt='bg'/>
                </div>
            </div>
               
            </div>
            <div key={2}>
            <div className=" md:grid-cols-2 gap-5 grid" >
            <div>
            <h1 className=' font-[800] mb-5 text-[32px] sm:text-[48px] '>Take advantage of the Exciting world of financial markets.</h1>
            <p className=' font-[500] mb-4 text-[16] sm:text-[24px]'>We are dedicated to providing you with a seamless and innovative trading experience</p>
            <Button
                    name='Trade now'
                />
            </div>
        <div>
            <img src={Bg1}  alt='bg'/>
        </div>
            </div>
            </div>
          </Slider>
        
        </div>
      );
    }
  }
