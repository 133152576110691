import React from 'react'
import NavBar from '../../Components/Navbar'
import Top from '../../Components/Contact/Top'
import Contactform from '../../Components/Contact/Contactform'
import Footer from '../../Components/Footer'

function Contactus() {
  return (
    <div>
      <NavBar/>
      <Top/>
      <Contactform/>
      <Footer/>
    </div>
  )
}

export default Contactus