import React from 'react'
import Back from '../../Components/Back'
import ForgetPasswordForm from '../../Components/ForgetPass/ForgetPasswordForm'

function ForgetPassword() {
  return (
    <div>
        <Back/>
        <ForgetPasswordForm/>
    </div>
  )
}

export default ForgetPassword