import React from 'react'

function AccessCom({img, title, con}) {
  return (
    <div className=' bg-[#0F0A0A] p-3 rounded-lg'>
        <img src={img}  className=' mb-3' alt={title}/>
        <h1 className=' font-[600] text-[24px] mb-3'>{title}</h1>
        <p className=' font-[500] text-[18px]'>{con}</p>
    </div>
  )
}

export default AccessCom