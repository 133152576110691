import React from 'react'
import FaqCom from './FaqCom'
import AccordionCard from './AccordionCard';

function Faq() {
  return (
    <div className=' mt-16 px-6 sm:px-24'>
        <h1 className=' my-7 text-[24px] sm:text-[44px] text-center font-[800] text-primary-color1 '>Frequently asked questions</h1>
       
        <div className="main-component">
        <div className="main-content -space-y-7 flex flex-col">
        {/* <div className=' relative ' >
        <AccordionCard
           header={"What is TOPPEXPRIME and how does it work "}
           con={"At Toppexprime, we understand that not everyone has the time, expertise, or confidence to trade independently."}
          color={'bg-[#D5C1FB]'}
          textColor={'text-[#14013A]'}
          zIndex={1}
        />
        </div> */}
       
        <div className=' '>
        <AccordionCard
          header={"What fees does TOPPEXPRIME charge?"}
           con={"No charged fees involved with investing with us."}
          color={'bg-[#14013A] '}
          textColor={'text-[#F2F1F3]'}
          zIndex={2}
        />
        </div>
        <div className=' '>
        <AccordionCard
          header={"Which cryptocurrencies can I invest in through TOPPEXPRIME"}
           con={"You can invest using the following chains: USDT (available on both ERC20 and TRC20), along with ETH and BTC."}
          color={'bg-[#8BD8B4]'}
          textColor={'text-[#14013A]'}
          zIndex={3}
        />
        </div>
        {/* <div className=' '>
        <AccordionCard
          header={"How can I withdraw my funds from TOPPEXPRIME"}
           con={"Withdrawal is done into your wallet address"}
          color={'bg-[#FAB55B]'}
          textColor={'text-[#14013A]'}
          zIndex={3}
        />
        </div> */}
        <div className=' z-40 '>
        <FaqCom
          // color={'bg-[#8046F2]'}
          //  textColor={'text-[#F2F1F3]'}
          color={'bg-[#FAB55B]'}
          textColor={'text-[#14013A]'}
           header={"Can I access my TOPPEXPRIME account from my mobile device?"}
           con={"Yes you can but for proper view we advice you use a large screen"}
                />
        </div>
        
        </div>
        </div>
      
    </div>
  )
}

export default Faq