import React from 'react'
import StoryCom from './StoryCom'

function Story() {
  return (
    <div className='px-4  md:px-28 my-[96px]'>
    <div className=' grid sm:grid-cols-2 gap-6'>
    <StoryCom
            title={"Copy Trading"}
            con={"Our advanced copy trading technology allows you to synchronize and follow the trades of professional traders. You can analyze their performance history, and choose the ones that align with your investment goals and risk appetite. Once you've synchronized with a trader, our platform will automatically execute the same trades on your account, ensuring you replicate their success."}
    />
         <StoryCom
            title={"Risk Management"}
            con={"We prioritize risk management and offer tools to help you manage your investments effectively. You can set your own risk preferences, including maximum trade sizes and stop-loss levels, to ensure your portfolio aligns with your risk tolerance. Additionally, our platform enables you to diversify your copy trading by allocating funds to multiple traders, spreading your risk across different strategies."}
        />
        <StoryCom
            title={"Diverse Asset Classes"}
            con={"We offer a wide range of asset classes to trade, including cryptocurrencies, stocks, and forex. Whether you're interested in the volatility of cryptocurrencies, the stability of stocks, or the global reach of forex markets, our platform provides you with the opportunity to diversify your portfolio and explore various investment options."}
    />
         <StoryCom
            title={"User-Friendly Interface"}
            con={"We believe in making trading accessible to everyone, regardless of their experience level. Our platform features a user-friendly interface with intuitive controls and comprehensive trading tools. Whether you're a novice trader or an experienced investor, you'll find our platform easy to navigate and use."}
        />
        <StoryCom
            title={"Robust security"}
            con={"We prioritize the security of your funds and personal information. Our platform implements industry-standard security measures, including encryption and two-factor authentication, to safeguard your account from unauthorized access. We work tirelessly to ensure that your trading experience is safe and protected."}
    />
         <StoryCom
            title={"Educational resources"}
            con={"We are committed to empowering our users with knowledge and expertise. We provide educational resources, including tutorials, webinars, and market analysis, to help you enhance your trading skills and stay updated with the latest market trends. Our goal is to support your growth as a trader and investor."}
        />
        <StoryCom
            title={"Transparent Performance Metrics"}
            con={"Transparency is essential when it comes to copy trading. To help you make informed decisions, we provide detailed performance metrics for each trader on our platform. You can review their historical returns, risk levels, trading frequency, and other relevant statistics to evaluate their performance and make well-informed choices."}
    />
        
    </div>
       <div className=' my-7 sm:w-[70%] mx-auto'>
            <p className=' font-[600] text-[20px] text-center'>We are excited to have you on board and look forward to providing you with a rewarding trading experience. Join our platform today and start benefiting from the success of top traders in the cryptocurrency, stocks, and forex markets.</p>
       </div>
    </div>
  )
}

export default Story