import React from 'react'
import TradingViewChart from '../../Components/Dashboard/Chart'
import CryptoPriceList from '../../Components/Copytrade/PriceList'
import Animate from '../../Components/Copytrade/Animate'

function CopyTrade() {
  return (
    <div>
      <TradingViewChart/>
      {/* <Animate/> */}
      <CryptoPriceList/>
    </div>
  )
}

export default CopyTrade