import React from 'react'
import I1 from '../../Assets/images/r1.png'
import I2 from '../../Assets/images/r2.png'
import I3 from '../../Assets/images/r3.png'
import AccessCom from './AccessCom'
function Access() {
  return (
    <div className=' px-4 md:px-28 my-[96px] '>
        <h1 className=' font-[800] md:w-[80%] text-[32px] mb-7 sm:text-[48px] '>We believe in making trading <span className=' text-primary-color1'>accessible to everyone regardless</span> of their experience level.</h1>
     <div className=' grid sm:grid-cols-3 gap-3'>
        <AccessCom
            img={I1}
            title={'Various assets'}
            con={"Multi-asset trading generate opportunitites for traders to diversify their portfolios and potentially increase returns."}
        />
        
        <AccessCom
            img={I2}
            title={'Market analysis'}
            con={"This is a powerful tool for investors and traders to enhance portfolio performance."}
        />
        
        <AccessCom
            img={I3}
            title={'Enhanced tools'}
            con={"Our advanced copy trading technology allows you to synchronize to professional traders."}
        />
        
    </div>
    </div>
  )
}

export default Access