import React, { useEffect, useState } from 'react'
import WalletCom from './WalletCom'
import Inputmain from '../SelectValue/inputMain'
import NonnegativeInputField from '../SelectValue/NonnegativeInputfield'
import Button from '../SelectValue/Button'
import getServices from '../../services/get-services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import postServices from '../../services/post-services'
import Loading from '../SelectValue/Loading'
function Wallets() {
    const [eth, setEth] = useState(false)
    const[btc, setBtc] = useState(false)
    const [usdt, setUsdt] = useState(false)
    const [name, setName] = useState('')
    const [amount, setAmount] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)
    const [walletTrans, setWalletTrans] = useState([])
    const [walletAddress, setWalletAddress] = useState('')
    const shouldDisplayText = eth || btc || usdt;
    const [loading, setLoading] = useState(false)
    const copyToClipboard = () => {
        navigator.clipboard.writeText(walletAddress)
          .then(() => {
            toast.success('Text copied to clipboard!');
          })
          .catch(err => {
            toast.error('Could not copy text');
          });
      };
    useEffect(() =>{
        getServices.getWalletList().then(
            res =>{
              if(res.data.statusCode === '200'){
                setWalletTrans(res.data.result)
              }
            }
        ).catch(
    
        )
      },[])
      useEffect(() => {
        
          const result = walletTrans.find(option => option.wallet_name === name);
          // setWalletAddress(result)
          console.log(result)
          if(result){
              setWalletAddress(result.wallet_address)
          }else{
              // setWalletAddress(result)
            console.log('novalue')
          }
      
    }, [name, walletTrans]); 
   
    
      const handleSubmit = (e) =>{
        setLoading(true)
            e.preventDefault()
            postServices.addFund(walletAddress,amount,transactionId,user.user_id).then(
                res =>{
                    setLoading(false)
                    if(res.data.statusCode === '200'){
                      toast.success(res.data.message, {
                                     
                        onClose: () => {
                          window.location.reload()
                        }
                      })
                    }else{
                      toast.error(res.data.message,
                        {
                          position: toast.POSITION.TOP_LEFT
                        })
                    }
                  }
            ).catch(
                err => {
                    setLoading(false)
                  }
            )
      }
  return (
    <div className=' my-4'>
    <ToastContainer/>
    <Loading
        open={loading}
    />
     <div className=' rounded-xl border bg-[#0F0A0A] border-primary-color p-4'>
       <h1 className=' text-[20px] font-bold mb-3 text-primary-color1'>Add funds to wallet</h1>
       <p className=' text-[16px] font-normal mb-4 text-white'>Choose one of the following payment modes to add funds to your wallet.</p>
       <div className=' sm:flex my-5  gap-5'>
        <WalletCom
            name={'Bitcoin Payment'}
            color={btc? 'bg-primary-color ':'bg-white hover:bg-primary-color'}
            onClick={() =>{
                setBtc(true)
                setName('Bitcoin')
                setEth(false)
                // check()
                setUsdt(false)
            }}
        />
         <WalletCom
            name={'Ethereum Payment'}
            color={eth? 'bg-primary-color ':'bg-white hover:bg-primary-color'}
            onClick={() =>{
                setBtc(false)
                setName('ETH')
                setEth(true)
                // check()
                setUsdt(false)
            }}
        />
         <WalletCom
            name={'USDT Payment'}
            color={usdt? 'bg-primary-color ':'bg-white hover:bg-primary-color'}
            onClick={() =>{
                setBtc(false)
                setEth(false)
                setName('USDT')
               
                setUsdt(true)
            }}

        />
       
       </div>
       {shouldDisplayText &&
        <div className=' my-6'>
            <h1 className=' text-center text-2xl text-white font-semibold mb-3'> {btc? 'Bitcoin': eth? 'Ethereum': usdt? 'USDT (TRC20)': ''} Payment Process</h1>
            <form onSubmit={handleSubmit}>
                <Inputmain
                    label={`Make payment to the below ${btc? 'Bitcoin': eth? 'Ethereum': usdt? 'USDT (TRC20)': ''} wallet address` }
                    value={walletAddress}
                    disabled
                />
                <div>
                    <p onClick={copyToClipboard} className=' p-3 inline-block bg-primary-color text-white rounded-lg'>Copy {btc? 'Bitcoin': eth? 'Ethereum': usdt? 'USDT (TRC20)': ''} Address</p>
                </div>
                
                <div className=' my-3'>
                    <NonnegativeInputField
                        label={"Amount in USD"}
                        valuee={amount}
                        onChange={setAmount}
                    />
                </div>
                <div className=' my-3'>
                <Inputmain
                    label={`Paste the transferred ${btc? 'Bitcoin': eth? 'Ethereum': usdt? 'USDT (TRC20)': ''} transaction ID` }
                    value={transactionId}
                    onChange={setTransactionId}
                />
               
                </div>
                <div className=' flex my-4 justify-end'>
                    <Button
                        name={'Deposit'}
                    />
                </div>
            </form>
            
        </div>
        }
     </div>
    </div>
  )
}

export default Wallets