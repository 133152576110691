import React, { useEffect, useState } from 'react'
// import TradingViewChart from '../../Components/Dashboard/Chart'
import CardCom from '../../Components/Dashboard/CardCom'
import getServices from '../../services/get-services'
import Loading from '../../Components/SelectValue/Loading'

function MainDashboard() {
  const [portBalance, setPortBalance] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [totalBonus, setTotalBonus] = useState(0)
  const [totalWithdrawal, setTotalWIthdrawal] = useState(0)
  const [totalDeposit, setTotalDeposit] = useState(0)
  const details = localStorage.getItem('userdetails')
  const user =  JSON.parse(details)
  const [loading, setLoading] = useState(false)
  useEffect(() =>{
    setLoading(true)
    getServices.getBalance(user.user_id).then(
        res =>{
          setLoading(false)
           if(res.data.statusCode === '200'){
            setPortBalance(res.data.result[0].portfolio_balance)
            setTotalBalance(res.data.result[0].total_profit)
            setTotalBonus(res.data.result[0].total_bonus)
            setTotalWIthdrawal(res.data.result[0].total_withdrawal)
            setTotalDeposit(res.data.result[0].amount_deposit)
           }
        }
    ).catch(
     err =>{
      setLoading(false)
     }
    )
  },[user.user_id])
  return (
    <div>
    <Loading 
      open={loading}
    />
    <div className=' mb-4  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
      <CardCom
        amount={portBalance}
        name={"Portfolio Balance"}
        con={"current available balance"}
      />
       <CardCom
        amount={totalBalance}
        name={"Total Profit"}
        con={"total profit so far"}
      />
       <CardCom
        amount={totalBonus}
        name={"Total Bonus"}
        con={"total bonus so far"}
      />
       <CardCom
        amount={totalWithdrawal}
        name={"Total Withdrawal"}
        con={"total withdrawal so far"}
      />
       <CardCom
        amount={totalDeposit}
        name={"Total Deposit"}
        con={"total Deposit so far"}
      />
    </div>
      {/* <TradingViewChart/> */}
    </div>
  )
}

export default MainDashboard